@import '~theme/viewport.module.css';

.FourOhFour {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewport-md) {
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 30px;
  }
}

.FourOhFour-headerWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    align-items: flex-start;
  }
}

.FourOhFour-headerMobile {
  @media (--viewport-md) {
    display: none;
  }
}

.FourOhFour-header {
  display: none;
  @media (--viewport-md) {
    display: block;
  }
}

.FourOhFour-subHeaderMobile {
  @media (--viewport-md) {
    display: none;
  }
}

.FourOhFour-subHeader {
  display: none;
  @media (--viewport-md) {
    display: block;
    margin-bottom: 15px;
  }
}

.FourOhFour-image {
  align-self: center;
  margin-bottom: 30px;
  max-width: 200px;

  @media (--viewport-lg) {
    max-width: 300px;
  }
}

.FourOhFour-linkList {
  list-style: none;
  margin: 0;
  padding: 0;
}
